export const FILTER_TAGS = [
  {
    key: 'golf',
    title: 'Golf',
    emoji: '⛳️'
  },
  {
    key: 'romantic',
    title: 'Romantik',
    emoji: '🌹'
  },
  {
    key: 'wellness',
    title: 'Wellness',
    emoji: '🧖‍♀️'
  },
  {
    key: 'sport',
    title: 'Sport',
    emoji: '🚵'
  },
  {
    key: 'adventure',
    title: 'Erlebnis',
    emoji: '🧗'
  },
  {
    key: 'sightseeing',
    title: 'Sightseeing',
    emoji: '🏞'
  },
  {
    key: 'family',
    title: 'Familie',
    emoji: '👨‍👩‍👧‍👦'
  },
  {
    key: 'hiking',
    title: 'Wandern',
    emoji: '🎒'
  },
  {
    key: 'relax',
    title: 'Relaxen',
    emoji: '🧘'
  },
  {
    key: 'shorttrip',
    title: 'Kurzurlaub',
    emoji: '⛱'
  },
  {
    key: 'shopping',
    title: 'Shopping',
    emoji: '👠'
  },
  {
    key: 'nature',
    title: 'Natur',
    emoji: '🌳'
  },
  {
    key: 'holiday',
    title: 'Holiday',
    emoji: '🏖'
  },
  {
    key: 'biking',
    title: 'Fahrrad',
    emoji: '🚴'
  },
  {
    key: 'animals',
    title: 'Tiere',
    emoji: '🐕'
  },
  {
    key: 'gourmet',
    title: 'Gourmet',
    emoji: '🥗'
  },
]

