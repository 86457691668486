import React, { useState, useEffect } from "react";
import {
  FormControl,
  TextField,
  Button
} from "@material-ui/core";
import { ALL_REGIONS } from "../json/regions";
import styles from "./filterBar.module.scss";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FILTER_TAGS } from "../json/tags";
import Emoji from "./Emoji";
import RegionSelect from "./RegionSelect";
import MapOutlined from "@material-ui/icons/MapOutlined";
import classNames from "classnames";

const EMTPY_QUERY = "";

const FilterBar = ({
  hotels,
  arrangements,
  onFilterSubmit,
  queryTags,
  updateQueryTags,
  queryRegion,
  updateQueryRegion,
  queryString,
  updateQueryString,
  onFilterUpdate,
  existingRegions,
  setShowMap,
  showMap
}) => {
  const [filteredHotels, setFilteredHotels] = useState([]);
  const [isFilterEmpty, setIsFilterEmpty] = React.useState(true);

  useEffect(() => {
    updateFilter();
  }, [queryString]);

  useEffect(() => {
    updateFilter(true);
  }, [queryTags, queryRegion]);

  const handleInputChange = event => {
    const query = event.target.value;
    updateQueryString(query);
    onFilterUpdate();
  };

  const handleSelect = event => {
    const queryRegion = event.target.value;
    updateQueryRegion(queryRegion);
    onFilterUpdate();
  };

  const updateFilter = (directUpdate = false) => {
    const filteredHotels = hotels.filter(hotel => {
      const {
        name,
        key,
        description,
        shortDescription,
        region
      } = hotel?.childMarkdownRemark?.frontmatter;

      // check region for region query
      const includesRegion =
        queryRegion === ALL_REGIONS
          ? true
          : region.toLowerCase().includes(queryRegion.toLowerCase());

      // check hotel name and description for query srting
      const includesName = name
        .toLowerCase()
        .includes(queryString.toLowerCase());
      const includesDescription =
        (description &&
          description.toLowerCase().includes(queryString.toLowerCase())) ||
        (shortDescription &&
          shortDescription.toLowerCase().includes(queryString.toLowerCase())) ||
        false;

      const hotelArrangements = arrangements.filter(
        arrangement => arrangement.childMarkdownRemark.frontmatter.key === key
      );

      const queryTagKeys = queryTags.map(tag => tag.key);

      /**
       *  this aproach filter wellness as well as nature
       */
      // check hotel tags for tags query ---> get all tags of all arranements in one array
      // const hotelTags = hotelArrangements.reduce((result, arrangement) => {
      //   const tags =
      //     arrangement.childMarkdownRemark.frontmatter.tags || undefined
      //   return tags ? [...result, ...tags] : result
      // }, [])

      // ODER - condition zB entweder wellness oder romanatik oder natur
      // const includesTags = !queryTagKeys.length
      //   ? true
      //   : !!hotelTags.find(tag => queryTagKeys.includes(tag))

      /**
       * this approach filters hotels with arrangements that offer wellness AND nature together
       */
      let includesTags = false;
      hotelArrangements.forEach(hotelArrangement => {
        const { tags } = hotelArrangement.childMarkdownRemark.frontmatter;

        if (!includesTags) {
          const hasAllQueryTags = queryTagKeys.every(
            queryTag => tags && tags.includes(queryTag)
          );
          if (hasAllQueryTags) {
            includesTags = true;
          }
        }
      });

      return (
        (includesName || includesDescription) && includesTags && includesRegion
      );
    });

    setFilteredHotels(filteredHotels);

    if (directUpdate) {
      onFilterSubmit(filteredHotels);
    }

    setIsFilterEmpty(
      queryString === EMTPY_QUERY &&
        queryRegion === ALL_REGIONS &&
        !queryTags.length
    );
  };

  const submitFilterHandler = () => {
    // update parent state
    onFilterSubmit(filteredHotels);
  };

  const clearFilterHandler = () => {
    updateQueryString(EMTPY_QUERY);
    updateQueryRegion(ALL_REGIONS);
    updateQueryTags([]);
    setFilteredHotels(hotels);

    onFilterSubmit(hotels);
    onFilterUpdate();
  };

  const handleAutoComplete = (event, newValue) => {
    updateQueryTags([...newValue]);
    onFilterUpdate();
  };

  const toggleMapButtonClassName = classNames(styles.toggleMapButton, styles.mobileHidden);

  return (
    <div className={styles.wrapper}>
      <div>
        <FormControl
          size="small"
          variant="outlined"
          className={styles.formControl}
        >
          <RegionSelect
            queryRegion={queryRegion}
            onChange={handleSelect}
            existingRegions={existingRegions}
          />
        </FormControl>
      </div>
      <div className={styles.autoCompleteWrapper}>
        <Autocomplete
          value={queryTags}
          onChange={handleAutoComplete}
          multiple
          limitTags={2}
          id="multiple-limit-tags"
          options={FILTER_TAGS}
          getOptionLabel={option => option.title}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label="Schlagwörter"
              placeholder="Schlagwörter"
            />
          )}
          renderOption={option => (
            <React.Fragment>
              {option.emoji && (
                <span style={{ paddingRight: 10 }}>
                  <Emoji tag={option} />{" "}
                </span>
              )}
              {option.title}
            </React.Fragment>
          )}
          size="small"
          style={{ minWidth: 300 }}
        />
      </div>
      {/* <div className={styles.searchInputWrapper}>
        <TextField
          id="outlined-basic"
          label="Suchbegriff"
          variant="outlined"
          onChange={handleInputChange}
          value={query}
          className={styles.searchInput}
          InputProps={{
            endAdornment: <InputAdornment position="end">
            <IconButton
              aria-label="search"
              onClick={submitFilterHandler}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>,
          }}
          // size="small"
        />
      </div> */}
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<ClearIcon />}
        aria-label="delete"
        onClick={clearFilterHandler}
        disabled={isFilterEmpty}
        size="medium"
        className={styles.mobileHidden}
      >
        Filter löschen
      </Button>

      <IconButton
        aria-label="delete"
        className={styles.mobileVisible}
        onClick={clearFilterHandler}
      >
        <ClearIcon fontSize="large" />
      </IconButton>

      <Button
        startIcon={<MapOutlined />}
        onClick={() => setShowMap(!showMap)}
        className={toggleMapButtonClassName}
      >
        {showMap ? "Karte verbergen" : "Karte anzeigen"}
      </Button>

    </div>
  );
};

export default FilterBar;
