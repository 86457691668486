import React from "react";
import styles from "./hero.module.scss";
import HeadlineCarousel from "./HeadlineCarousel";
import DoubleArrowTwoToneIcon from "@material-ui/icons/DoubleArrowTwoTone";
import { useState } from "react";
import Relax from "../assets/relaxen.svg";
// import Img from "gatsby-image";
import CloudinaryImage from "./CloudinaryImage/index";
import PropTypes from "prop-types";

const Hero = ({ heroHotel, onButtonClick }) => {
  const [icon, setIcon] = useState(<Relax />);

  const heroImage =
    heroHotel &&
    heroHotel.childMarkdownRemark &&
    heroHotel.childMarkdownRemark.frontmatter &&
    heroHotel.childMarkdownRemark.frontmatter.hero;

  const heroFootnote =
    heroHotel &&
    heroHotel.childMarkdownRemark &&
    heroHotel.childMarkdownRemark.frontmatter &&
    `${heroHotel.childMarkdownRemark.frontmatter.name}, ${heroHotel.childMarkdownRemark.frontmatter.region}`;

  const heroUrl =
    heroHotel &&
    heroHotel.childMarkdownRemark &&
    heroHotel.childMarkdownRemark.frontmatter &&
    heroHotel.childMarkdownRemark.frontmatter.website;

  const updateIcon = icon => {
    setIcon(icon);
  };

  return (
    <div className={styles.wrapper}>
      {/* kevin cloudinary */}
      {heroImage && (
        // <Img className={styles.image} loading="lazy" fluid={heroImage} />
        <CloudinaryImage
          base64={heroImage.childImageSharp.fluid.base64}
          aspectRatio={heroImage.childImageSharp.fluid.aspectRatio}
          url={heroImage.publicURL}
          // http://cdn.sanity.io/static/2cc9cf5676efd05becf1e126570168bc/kurzurlaub-am-niederrhein-6-tage.jpg
          sizes={"(min-width: 40em) 50vw, (min-width: 62em) 35vw, (min-width: 106em) 25vw, 100vw"}
          className={styles.image}
        />
      )}
      <div className={styles.shade}></div>
      <div className={styles.container}>
        <div className={styles.box} onClick={onButtonClick}>
          <div className={styles.box__inner}>
            <h1 className={styles.box__headline}>
              <HeadlineCarousel
                onFinished={onButtonClick}
                carouselCallback={updateIcon}
              />
            </h1>
            {icon && <div className={styles.box__icon}>{icon}</div>}
          </div>
        </div>
      </div>
      <DoubleArrowTwoToneIcon
        onClick={onButtonClick}
        className={styles.arrow}
      />
      <a
        href={`${heroUrl}`}
        target="_blank"
        rel="noreferrer"
        className={styles.footer}
      >
        {heroFootnote}
      </a>
    </div>
  );
};

Hero.propTypes = {
  heroHotel: PropTypes.object,
  onButtonClick: PropTypes.func,
};

export default Hero;
