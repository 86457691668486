import React, { useRef, useState, useEffect } from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import { Grid } from "@material-ui/core";
import HotelCard from "../components/HotelCard";
import { graphql } from "gatsby";
import Hero from "../components/Hero";
import FilterBar from "../components/FilterBar";
import { Sticky } from "react-sticky";
import Trees from "../assets/nodata.svg";
import NoData from "../components/NoData";
import FilterTagsImages from "../components/FilterTagsImages";
import { ALL_REGIONS } from "../json/regions";
import Headline from "../components/Headline";
import RegionSelectWrapper from "../components/RegionSelectWrapper";
import LeafletMap from "../components/LeafletMap";
import smoothscroll from "smoothscroll-polyfill";

const HEADER_HEIGHT_FIXED = 60;
const FILTER_HEIGHT = 80;
const RESULTS_TEXT_HEIGHT = 50;
const EMTPY_QUERY = "";


const scrollToRef = (ref, offset = HEADER_HEIGHT_FIXED) => {
  window.scrollTo({
    top: ref.current.offsetTop - offset,
    behavior: "smooth"
  });
};
const IndexPage = ({ data }) => {
  const regionsRef = useRef(null);
  const filterImagesRef = useRef(null);
  const hotelsRef = useRef(null);
  // const coronaRef = useRef(null);
  const allArrangements = data.arrangements.nodes.filter(
    arrangement => !arrangement.childMarkdownRemark.frontmatter.inactive
  );
  const allHotels = data.allFile.nodes.filter(
    hotel => {
      return !hotel.childMarkdownRemark?.frontmatter?.inactive;
    }
  );

  const allHotelsRegions = allHotels.map(
    hotel => hotel.childMarkdownRemark?.frontmatter.region
  );

  // remove duplicates
  const existingRegions = Array.from(new Set(allHotelsRegions));

  const totalNumberOfHotels = allHotels.length;
  const [hotels, setHotels] = useState(allHotels);
  const [heroHotel, setHeroHotel] = useState({});

  const [showMap, setShowMap] = useState(true);

  const [query, setQuery] = useState(EMTPY_QUERY);
  const [queryTags, setQueryTags] = React.useState([]);
  const [queryRegion, setQueryRegion] = useState(ALL_REGIONS);

  const [hoveredHotelKey, setHoveredHotelKey] = useState();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const numberHotels = allHotels.length;
    const randomIndex = Math.floor(Math.random() * numberHotels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const randomHotel = allHotels[randomIndex] || null;

    setHeroHotel(randomHotel);

    // kick off the polyfill!
    smoothscroll.polyfill();

    window.addEventListener("resize", onWindowResized);
    return () => {
      window.removeEventListener("resize", onWindowResized);
    };
  }, []);

  const onWindowResized = () => {
    if (window.innerWidth < 1280 && showMap) {
      setShowMap(false);
    }
  };

  const handleFilterSubmit = hotels => {
    setHotels(hotels);
  };

  const handleTagsImageClick = tag => {
    const queryTags = [tag];
    setQueryTags(queryTags);
    handleFilterUpdate();
  };

  const getFilterResultTitle = numberHotel => {
    if (!numberHotel) return "";
    return numberHotel > 1
      ? `${numberHotel} Hotels mit passenden Arrangements gefunden`
      : `${numberHotel} Hotel mit passendem Arrangement gefunden`;
  };

  const handleFilterUpdate = () => {
    if (window.pageYOffset !== 0) {
      scrollToRef(hotelsRef, HEADER_HEIGHT_FIXED + FILTER_HEIGHT);
    }
  };

  const gridProps = {
    xs: 12,
    md: showMap ? 12 : 6,
    lg: showMap ? 12 : 4
  };

  return (
    <React.Fragment>
      {showMap && (
        <LeafletMap locations={hotels} highlightedHotelKey={hoveredHotelKey} />
      )}
      {heroHotel && (
        <Hero
          heroHotel={heroHotel}
          // onButtonClick={() => scrollToRef(coronaRef)}
          onButtonClick={() => scrollToRef(regionsRef)}
        />
      )}

      <div ref={regionsRef}>
        <RegionSelectWrapper
          queryRegion={queryRegion}
          updateQueryRegion={setQueryRegion}
          onChanged={() => scrollToRef(filterImagesRef)}
          existingRegions={existingRegions}
        />
      </div>
      <div ref={filterImagesRef}>
        <FilterTagsImages onClick={tag => handleTagsImageClick(tag)} />
      </div>

      <Layout page="main" fullWidth={showMap}>
        <SEO
          title="Aus Liebe zur Heimat"
          description="Erleben Sie einen tollen Urlaub in außergewöhnlichen Hotels in Deutschland. Ob Wellness, Wandern oder ein Romantik-Wochenende - hier werden Sie fündig."
        />

        <Sticky topOffset={-HEADER_HEIGHT_FIXED}>
          {({ style, isSticky }) => (
            <div
              style={{
                ...style,
                position: isSticky ? "fixed" : "relative",
                zIndex: 2,
                top: isSticky ? `${HEADER_HEIGHT_FIXED}px` : "0"
              }}
            >
              <FilterBar
                hotels={allHotels}
                arrangements={allArrangements}
                onFilterSubmit={handleFilterSubmit}
                queryTags={queryTags}
                updateQueryTags={setQueryTags}
                queryRegion={queryRegion}
                updateQueryRegion={setQueryRegion}
                queryString={query}
                updateQueryString={setQuery}
                onFilterUpdate={handleFilterUpdate}
                existingRegions={existingRegions}
                setShowMap={setShowMap}
                showMap={showMap}
              />
            </div>
          )}
        </Sticky>
        <div ref={hotelsRef} style={showMap ? { width: 840 } : {}}>
          {hotels.length > 0 && (
            <>
              {hotels.length < totalNumberOfHotels && (
                <Headline style={{ height: RESULTS_TEXT_HEIGHT }}>
                  {getFilterResultTitle(hotels.length)}
                </Headline>
              )}
              <Grid container spacing={3} id="hotels">
                {/* <Grid key={999} item >
                      <HotelCard node={node} isVisible={true} />
                    </Grid> */}
                {hotels.map((node, i) => {
                  return (
                    <Grid key={i} item {...gridProps}>
                      <HotelCard
                        node={node}
                        isVisible={true}
                        onMouseEnter={() =>
                          setHoveredHotelKey(
                            node.childMarkdownRemark.frontmatter.key
                          )
                        }
                        onMouseLeave={() => setHoveredHotelKey(undefined)}
                        listMode={showMap}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
          {hotels.length === 0 && (
            <NoData text="Wir konnten leider kein Hotel zu den Suchkriterien finden." />
          )}
          <Trees style={{ margin: "auto", display: "block", width: "100%" }} />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export const getData = graphql`
  {
    allFile(
      filter: { sourceInstanceName: { eq: "hotels" }, extension: { eq: "md" } }
      sort: { fields: childMarkdownRemark___frontmatter___key, order: ASC }
    ) {
      totalCount
      nodes {
        childMarkdownRemark {
          frontmatter {
            key
            name
            region
            shortDescription
            description
            phone
            email
            contactPerson
            contactEmail
            website
            inactive
            lat
            lng
            hero {
              publicURL
              name
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                }
              }
            }
            logo {
              childImageSharp {
                fixed(height: 60) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    arrangements: allFile(
      filter: {
        sourceInstanceName: { eq: "arrangements" }
        extension: { eq: "md" }
      }
    ) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            key
            title
            tags
            teaserText
            description
            arrangementHero {
              big: childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        slogan
        description
      }
    }
  }
`;

export default IndexPage;
