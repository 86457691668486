import React from "react";
import { FILTER_TAGS } from "../json/tags";
import styles from "./filterTagsImages.module.scss";
import { Container, Grid, Chip } from "@material-ui/core";
// import Card from "@material-ui/core/Card";
// import FilterTagsImage from "./FilterTagsImage";
// import FaceIcon from "@material-ui/icons/Face";
import PropTypes from "prop-types";

function compare(a, b) {
  return a.title > b.title ? 1 : -1;
}

const FilterTagsImages = ({onClick}) => {
  return (
    <div className={styles.wrapper}>
      <Container maxWidth="md" style={{textAlign: "center", maxWidth: 600}}>
        <h2 className={styles.headline}>Auf was haben Sie Lust?</h2>
        {FILTER_TAGS.sort(compare).map((tag, index) => (
          <Chip key={index} label={tag.title} className={styles.chip} onClick={() => onClick(tag)} />
        ))}
      </Container>
    </div>
  );
};

FilterTagsImages.propTypes = {
  onClick: PropTypes.func,
};

export default FilterTagsImages;
