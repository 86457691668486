import React from "react"
import styles from "./nodata.module.scss"

const NoData = ({ text }) => {
  return (
  <div className={styles.wrapper}>{text}</div>
  )
}

export default NoData
