import React, { useState } from "react"
import styles from "./regionSelectWrapper.module.scss"
import { Button, FormControl } from "@material-ui/core"
import RegionSelect from "./RegionSelect"

const RegionSelectWrapper = ({
  queryRegion,
  updateQueryRegion,
  onChanged,
  existingRegions
}) => {
  const handleSelect = event => {
    const queryRegion = event.target.value
    updateQueryRegion(queryRegion)
    onChanged()
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.headline}>Wo möchten Sie hin?</h2>
      <FormControl variant="outlined">
        <RegionSelect
          queryRegion={queryRegion}
          onChange={handleSelect}
          existingRegions={existingRegions}
        />
      </FormControl>
      <Button variant="contained" color="primary" onClick={onChanged} className={styles.button} title="Weiter">Weiter</Button>
    </div>
  )
}

export default RegionSelectWrapper
