import React from "react"

const Emoji = ({tag, ...rest}) => {
  const {title, emoji} = tag;
  return (
    <span
      className="emoji"
      role="img"
      aria-label={title ? title : ""}
      aria-hidden={title ? "false" : "true"}
      {...rest}
    >
      {emoji}
    </span>
  )
}

export default Emoji
