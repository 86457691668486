import React from "react"
import { ALL_REGIONS, REGIONS } from "../json/regions"
import { InputLabel, Select } from "@material-ui/core"

const RegionSelect = ({ queryRegion, onChange, existingRegions }) => {
  return (
    <>
      <InputLabel htmlFor="outlined-age-native-simple">Bundesland</InputLabel>
      <Select
        native
        value={queryRegion}
        onChange={onChange}
        label="Bundesland"
        inputProps={{
          name: "Bundesland",
          id: "outlined-age-native-simple"
        }}
      >
        <option value={ALL_REGIONS}>Alle Bundesländer</option>
        {REGIONS.map(region => (
          <option
            key={region.id}
            value={region.label}
            disabled={!existingRegions.includes(region.label)}
          >
            {region.label}
          </option>
        ))}
      </Select>
    </>
  )
}

export default RegionSelect
