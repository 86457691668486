import React from "react"
import styles from "./headline.module.scss"
import classNames from "classnames"

const Headline = ({
  component = "h4",
  variant = component,
  color = "default",
  className,
  children,
  style
}) => {
  const DynamicComponent = component

  const headlineClass = classNames(styles.wrapper, className, {
    [styles[variant]]: true,
    [styles[color]]: true
  })

  return (
    <DynamicComponent className={headlineClass} style={style}>
      {children}
    </DynamicComponent>
  )
}

export default Headline
