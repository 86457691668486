import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import styles from "./hotelCard.module.scss";
import Img from "gatsby-image";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ContactIcons from "./ContactIcons";
import classNames from "classnames";
import CloudinaryImage from "./CloudinaryImage/index";

const HotelCard = ({ node, onMouseEnter, onMouseLeave, listMode = true }) => {
  if (!node.childMarkdownRemark) return null;

  const { frontmatter, fields } = node.childMarkdownRemark;
  const {
    name,
    region,
    shortDescription,
    phone,
    email,
    website,
    hero,
    logo
  } = frontmatter;

  const { slug } = fields;
  const HEADER_HEIGHT = 200;
  const hotelDetailUrl = `${slug}`;
  const heroStyle = listMode
    ? {
      height: "100%",
    }
    : {
      height: HEADER_HEIGHT
    };

    const cardActionStyle = listMode
    ? {
      flex: "0 0 200px"
    }
    : {};

  const textStyleDefault = {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: 14,
    marginBottom: 10,
    paddingTop: 30
  };

  const textStyle = listMode
    ? {
      ...textStyleDefault,
      textAlign: "left",
      paddingTop: 0,
    } : textStyleDefault;

  const classNameWrapper = classNames(styles.wrapper, {
    [styles.wrapper__list]: listMode,
  });

  return (
    <Card
      className={classNameWrapper}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {/* kevin cloudinary */}
      <CardActionArea href={hotelDetailUrl} target="_blank" style={cardActionStyle}>
        {hero && (
          //   <Img
          //     loading="lazy"
          //     title={name}
          //     alt={name}
          //     fluid={hero.small.fluid}
          //     style={heroStyle}
          //   />
          // )}
          <CloudinaryImage
              base64={hero.childImageSharp.fluid.base64}
              // aspectRatio={hero.childImageSharp.fluid.aspectRatio}
              url={hero.publicURL}
              // http://cdn.sanity.io/static/2cc9cf5676efd05becf1e126570168bc/kurzurlaub-am-niederrhein-6-tage.jpg
              sizes={"(min-width: 40em) 50vw, (min-width: 62em) 35vw, (min-width: 106em) 25vw, 100vw"}
              style={heroStyle}
          />
        )}

        {!hero && (
          <div
            style={ {...heroStyle, background: "#ff7e67"} }
            title={name}
          />
        )}

        {logo && (
          <div className={styles.logoWrapper}>
            <div className={styles.logo}>
              <Img
                className={styles.logoImg}
                loading="lazy"
                title={name}
                alt={name}
                fixed={logo.childImageSharp.fixed}
              />
            </div>
          </div>
        )}
      </CardActionArea>
    <div className={styles.cardInnerWrapper}>
      <CardContent className={styles.cardContent}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          component="p"
          style={textStyle}
        >
          {region}
        </Typography>
        <h2 className={styles.headline}>{name}</h2>
        <div variant="body2" component="p">
          <div className={styles.copy}>
            {shortDescription && shortDescription}
          </div>
        </div>
      </CardContent>
      <CardActions className={styles.actionBar}>
        {(phone || email || website) && (
          <ContactIcons
            phone={phone}
            email={email}
            website={website}
            hotelName={name}
            arrangementName={name}
          />
        )}
        <Button
          variant="outlined"
          size="small"
          href={hotelDetailUrl}
          className={styles.button}
          endIcon={<NavigateNextIcon />}
          target="_blank "
        >
          Mehr
        </Button>
      </CardActions>
      </div>
    </Card>
  );
};

export default HotelCard;
