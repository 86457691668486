import React, { useState } from "react"
import Typist from "react-typist"
import Nature from "../assets/nature.svg"
import Relax from "../assets/relaxen.svg"
import Wandern from "../assets/wandern.svg"
import Wellness from "../assets/wellness.svg"
import Family from "../assets/family.svg"
import Romantic from "../assets/romantic.svg"
import DreamHoliday from "../assets/dreamholiday.svg"
import styles from "./headlineCarousel.module.scss"
import { Button } from "@material-ui/core"

const FIRST_ROW_LABEL = "Lust auf "

const moods = [
  {
    label: "Auszeit",
    icon: <Relax />
  },
  {
    label: "Wanderurlaub",
    icon: <Wandern />
  },
  {
    label: "Natur pur",
    icon: <Nature />
  },
  {
    label: "Romantik",
    icon: <Romantic />
  },
  {
    label: "Wellness",
    icon: <Wellness />
  },
  {
    label: "Familienurlaub",
    icon: <Family />
  }
]

const renderTypistList = () => {
  return moods.map(({ label }, index) => {
    const currentWordLength = label.length + 1
    const allWordsLength = FIRST_ROW_LABEL.length + currentWordLength

    const count =
      moods.length - 1 === index ? allWordsLength : currentWordLength

    return (
      <span key={index}>
        <span>{label}?</span>
        <Typist.Backspace count={count} delay={2000} />
      </span>
    )
  })
}

const HeadlineCarousel = ({ carouselCallback, onFinished }) => {
  const onTypingDone = () => {
    carouselCallback(<DreamHoliday />)
    // setTimeout(onFinished, 2000);
  }

  return (
    <div className={styles.wrapper}>
      <Typist
        stdTypingDelay={0}
        onTypingDone={() => onTypingDone()}
        onLineTyped={line => {
          const mood = moods.find(mood => mood.label === line)
          if (mood) {
            carouselCallback(mood.icon)
          }
        }}
        cursor={{
          show: true,
          blink: true,
          element: "|",
          hideWhenDone: false,
          hideWhenDoneDelay: 1000
        }}
      >
        <div>{FIRST_ROW_LABEL}</div>
        {renderTypistList()}
        <span>Entdecken Sie Deutschland!</span>
      </Typist>
      <Button className={styles.ctaButton} variant="contained" color="primary">Los geht´s!</Button>
    </div>
  )
}

export default HeadlineCarousel
