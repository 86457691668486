import React from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import { useState } from "react";
import styles from "./leafletMap.module.scss";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import marker from "../images/marker.svg";
import markerHover from "../images/marker_hover.svg";
import CloudinaryImage from "./CloudinaryImage/index";

const DEFAULT_ICON_CONFIG = {
  iconUrl: marker,
  iconRetinaUrl: marker,
  iconAnchor: [16, 16],
  popupAnchor: [0, -15],
  iconSize: [32, 32],
  // shadowUrl: "../assets/images/icon-home-shadow.svg",
  shadowSize: [32, 32],
  shadowAnchor: [16, 12]
};

const HOVER_ICON_CONFIG = {
  iconUrl: markerHover,
  iconRetinaUrl: markerHover
};

let pointerIcon;
let pointerIconHover;

if (typeof window !== "undefined") {
  pointerIcon = new L.Icon(DEFAULT_ICON_CONFIG);
  pointerIconHover = new L.Icon({
    ...DEFAULT_ICON_CONFIG,
    ...HOVER_ICON_CONFIG
  });
}

const WIDTH_POPOVER = 200;

const LeafletMap = ({ locations, highlightedHotelKey }) => {
  const [lat, setLat] = useState(50.9304757);
  const [lng, setLng] = useState(7.8211941);
  const [zoom, setZoom] = useState(7);
  const position = [lat, lng];

  const highlightMarker = e => {
    e.target.setIcon(pointerIconHover);
  };

  const handleMarkerOnMouseOver = e => {
    highlightMarker(e);
  };

  const handleMarkerOnMouseOut = e => {
    e.target.setIcon(pointerIcon);
  };

  if (typeof window !== "undefined") {
    return (
      <aside className={styles.wrapper}>
        <Map
          center={position}
          zoom={zoom}
          enter={position}
          // bounds={data.map(location => [location.coords.lat, location.coords.lng])}
          className={styles.map}
          tap={false} // fix: https://github.com/Leaflet/Leaflet/issues/7331
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {locations
            .filter(location => {
              const { frontmatter } = location.childMarkdownRemark;
              return frontmatter.lat && frontmatter.lng;
            })
            .map((location, index) => {
              const { frontmatter, fields } = location.childMarkdownRemark;
              const { hero, key } = frontmatter;

              return (
                <Marker
                  key={index}
                  position={[frontmatter.lat, frontmatter.lng]}
                  icon={!pointerIcon
                    ? null
                    : key === highlightedHotelKey ? pointerIconHover : pointerIcon
                  }
                  onMouseOver={e => handleMarkerOnMouseOver(e)}
                  onMouseOut={e => handleMarkerOnMouseOut(e)}
                >
                  <Popup minWidth={WIDTH_POPOVER} maxWidth={WIDTH_POPOVER}>
                    <div className={styles.popup__wrapper}>
                      <a href={fields.slug} target="_blank" rel="noreferrer">
                        {hero && (
                          <CloudinaryImage
                            base64={hero.childImageSharp.fluid.base64}
                            aspectRatio={hero.childImageSharp.fluid.aspectRatio}
                            url={hero.publicURL}
                            // http://cdn.sanity.io/static/2cc9cf5676efd05becf1e126570168bc/kurzurlaub-am-niederrhein-6-tage.jpg
                            sizes={"(min-width: 40em) 50vw, (min-width: 62em) 35vw, (min-width: 106em) 25vw, 100vw"}
                            className={styles.popup__image}
                        />
                          // <Img
                          //   loading="lazy"
                          //   title={name}
                          //   alt={name}
                          //   fluid={hero.small.fluid}
                          //   className={styles.popup__image}
                          // />
                        )}
                        {frontmatter.name}
                      </a>
                    </div>
                  </Popup>
                </Marker>
              );
            })}
        </Map>
      </aside>
    );
  }
  return null;
};

LeafletMap.propTypes = {
  locations: PropTypes.array,
  highlightedHotelKey: PropTypes.string
};

export default LeafletMap;
